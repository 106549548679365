import { render, staticRenderFns } from "./LatestPrivacyContract.vue?vue&type=template&id=92a77130&scoped=true&"
import script from "./LatestPrivacyContract.vue?vue&type=script&lang=js&"
export * from "./LatestPrivacyContract.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92a77130",
  null
  
)

export default component.exports