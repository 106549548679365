<template>
    <section>
        <div class="container-fluid">
            <div class="col-md-12">
                <div class="alt-2"></div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="avivon-heading mb-30px">
                            <h1 class="big_title text-center">
                                Términos, Condiciones y Avisos de Uso del Sitio.
                                <br><br>
                            </h1>
                            <p class="avivon__desc mt-10px text-primary">
                                Fecha de actualización: 13 de mayo de 2022
                            </p>
                        </div>
                        <!-- INTRODUCCIÓN -->
                        <p class="text-justify">
                            Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo (en lo sucesivo e indistintamente "<u>nosotros</u>", "<u>nuestro</u>" y "<u>Red Girasol</u>") les da la bienvenida a <a href="https://www.redgirasol.com/" style="color: blue"><u>https://www.redgirasol.com/</u></a> (el “Sitio”). 
                            En virtud de lo cual todos los usuarios garantizan que (i) son mayores de 18 años, (ii) cuentan con capacidad de goce, de ejercicio y para obligarse, (iii) usarán el Sitio de conformidad con éstos Términos, Condiciones y Avisos; (iv) sólo usará el Sitio en nombre y por cuenta propia o por cuenta de 
                            una persona respecto de la cual tenga la autorización expresa y se encuentre debidamente facultado para actuar en su nombre, de conformidad con lo previsto en el Artículo 11 de las Disposiciones de Carácter General a que se refiere el Artículo 58 de la Ley para Regular las Instituciones de Tecnología Financiera; 
                            (v) dará a conocer a tales personas los términos y condiciones aplicables a las operaciones realizadas por cuenta y a nombre de ellas, incluyendo cualquier regla o restricción y (vi) toda la información que proporcionen en este Sitio será veraz, precisa, completa, actualizada y no dañará derechos de terceros 
                            (un “<u>Usuarios</u>” y en conjunto los “<u>Usuarios</u>”). Expuesto lo anterior, los Usuarios deberán adherirse a los siguientes términos y condiciones de uso.
                            <br><br>
                            En cumplimiento de lo dispuesto en las Disposiciones de Carácter General de la CONDUSEF en materia de transparencia y sanas prácticas aplicables a las Instituciones de Tecnología Financiera (las “<u>Disposiciones CONDUSEF</u>”), Red Girasol hace del conocimiento de los Usuarios, la siguiente información relacionada con el Sitio:
                        </p>
                        
                        <ol class="text-justify" style="list-style-type: decimal;font-weight: bold; margin-top: 1rem;border-bottom: solid 3px;padding-bottom: 2rem;margin-bottom: 3rem;">
                            <li style="padding-left: 4rem;">
                                Usos del sitio.
                            </li>
                            <li style="padding-left: 4rem;">
                                Restricciones del Sitio.
                            </li>
                            <li style="padding-left: 4rem;">
                                Responsabilidad de los Usuarios respecto del Sitio.
                            </li>
                            <li style="padding-left: 4rem;">
                               Disponibilidad del Sitio.
                            </li>
                            <li style="padding-left: 4rem;">
                                Misceláneas.
                            </li>
                        </ol>


                        <ol class="text-justify" style="list-style-type: decimal;font-weight: bold; margin-top: 1rem;">
                            <!-- 1. Usos del sitio -->
                            <li>
                                Usos del Sitio.<br><br>
                                <ol>
                                    <!-- 1.1.	Introducción al Sitio. -->
                                    <li style="font-size:16px!important">
                                        Introducción al Sitio.
                                        <span style="font-weight: normal;">
                                            El Sitio opera de dos diferentes maneras. En una primera instancia, opera como una plataforma de información sobre (i) Red Girasol y (ii) los servicios que prestamos. 
                                            Red Girasol ofrece, por medio del Sitio, los servicios de financiamiento colectivo de deuda para la adquisición de productos propiedad de los Proveedores (los “<u>Productos</u>”) 
                                            en los Estados Unidos Mexicanos, mediante el registro de los Usuarios a éste Sitio y la celebración de diversos contratos que conforme al registro de los Usuarios, 
                                            deban acordar (el “<u>Negocio</u>”). En su segunda acepción, el Sitio opera mediante el registro de los Usuarios en el Sitio, bajo cualquiera de las siguientes modalidades, 
                                            como: (i) inversionista, (ii) solicitante o (iii) proveedor. Bajo este segundo funcionamiento del Sitio es como los Usuarios podrán acceder al Negocio. 
                                            Los inversionistas, son aquellas personas físicas o morales, que registradas en el Sitio como tal, aportarán recursos a los Solicitantes para financiar colectivamente 
                                            los Productos (según dicho término se define más adelante) (un “<u>Inversionista</u>” o en conjunto los <u>Inversionistas</u>). Los solicitantes son aquellas personas, físicas o morales, 
                                            que registradas en el Sitio como tal, hubiesen requerido los recursos de los Inversionistas para adquirir, los Productos de los Proveedores (según dicho término se define más adelante) 
                                            (un “<u>Solicitante</u>” o en conjunto los “<u>Solicitantes</u>”). Los proveedores son aquellas personas físicas o morales, que registradas en el Sitio como tal, vendan e instalen los Productos a los Solicitantes, 
                                            mediante el financiamiento de los Inversionistas (un “<u>Proveedor</u>” o en conjunto los “<u>Proveedores</u>”).
                                        </span>
                                    </li><br>
                                    <!-- 1.2	Adherencia a estos Términos, Condiciones y Avisos de Uso del Sitio. -->
                                    <li style="font-size:16px!important">
                                        Adherencia a estos Términos, Condiciones y Avisos de Uso del Sitio.
                                        <span style="font-weight: normal;">
                                            Por lo tanto, todos los Usuarios que intervengan en el Negocio, deberán adherirse a los presentes Términos, Condiciones y Avisos de uso del Sitio y dicha adherencia será consentida expresa y plenamente mediante el uso del Sitio y su registro como Inversionista, 
                                            Solicitante o Proveedor en el mismo; así como a todos los demás contratos que deban suscribir en virtud de su registro al Sitio y de su participación en el Negocio.
                                            <br><br>
                                            Asimismo, los Usuarios, reconocen y aceptan que la adherencia a los presentes Términos, Condiciones y Avisos implica también su sometimiento expreso y pleno al Aviso de Privacidad previsto en el Sitio, para efectos de participar en el Negocio, 
                                            mismo que podrá ser consultado en: <a target="_blank" href="https://www.redgirasol.com/aviso-de-privacidad">Aviso de Privacidad</a>. Además, los Usuarios reconocen que las comisiones vigentes por la prestación del Negocio son las siguientes: <a target="_blank" href="https://www.redgirasol.com/comisiones">Consulta las comisiones de nuestro crédito</a>.
                                            <br><br>
                                            La adherencia a cualquier instrumento relacionado o derivado con el Sitio y/o del Negocio podrá ser establecida mediante la firma electrónica establecida por el Sitio, misma que cumple con los requisitos legales aplicables y dicha firma electrónica servirá para todos 
                                            los efectos legales a que haya lugar como firma autógrafa y expresa del consentimiento de los Usuarios y como determinante de su voluntad para someterse a los instrumentos jurídicos en cuestión.
                                        </span>
                                    </li><br>
                                    <!-- 1.3	Operaciones y servicios que pueden realizar en el Sitio.   -->
                                    <li style="font-size:16px!important">
                                        Operaciones y servicios que pueden realizar en el Sitio.
                                        <div class="table-responsive-sm">
                                            <!-- TABLA DE OPERACIONES Y SERVICIOS -->
                                            <table class="custom-table" style="margin-top: 15px;">
                                                <caption></caption>
                                                <thead>
                                                    <tr>
                                                        <!-- Sin headers -->
                                                    </tr>
                                                </thead>
                                                <tbody style="font-weight: normal;">
                                                    <tr>
                                                        <td>
                                                            Contratación de crédito simple con garantía prendaria.
                                                        </td>
                                                        <td>
                                                            Contratación de crédito simple con garantía prendaria para personas físicas o morales.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Inversión manual de proyectos.
                                                        </td>
                                                        <td>
                                                            Inversión en proyectos de forma manual.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Inversión automática de proyectos.
                                                        </td>
                                                        <td>
                                                            Inversión en proyectos de forma automatizada en base a parámetros preestablecidos.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Simulación de créditos.
                                                        </td>
                                                        <td>
                                                            Simulación de créditos para que el Solicitante evalúe las condiciones más adecuadas para su crédito.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Alta y modificación de solicitudes de créditos.
                                                        </td>
                                                        <td>
                                                            Generación de la solicitud del crédito proporcionando los datos requeridos para su evaluación.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Alta y modificación de beneficiarios.
                                                        </td>
                                                        <td>
                                                            Registro y actualización de información de beneficiarios de la cuenta.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Consulta de información de los productos y servicios.
                                                        </td>
                                                        <td>
                                                            Consulta de toda información relacionada a los productos y servicios contratados así como de las operaciones realizadas, incluyendo estados de cuenta.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Domiciliación de pago.
                                                        </td>
                                                        <td>
                                                            Servicio para poder realizar abonos a través de domiciliación.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Abono a capital y liquidación de crédito.
                                                        </td>
                                                        <td>
                                                            Facilidad del Solicitante para abonar a capital disminuyendo así los pagos futuros a realizar así como la liquidación completa de su crédito.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                          Modificación de los medios para notificaciones.
                                                        </td>
                                                        <td>
                                                            Facilidad para que los Usuarios puedan ser notificados a otra dirección de correo electrónico.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Solicitud de aclaraciones.
                                                        </td>
                                                        <td>
                                                            Facilidad para que los Usuarios puedan realizar aclaraciones sobre alguna operación o servicio.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Solicitud de retiros.
                                                        </td>
                                                        <td>
                                                            Servicio para que el Inversionista pueda solicitar retirar fondos a su cuenta bancaria previamente verificada.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Cancelación de contratos.
                                                        </td>
                                                        <td>
                                                            Capacidad para que los Usuarios puedan cancelar los contratos cuando las condiciones así lo permitan.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Modificación de los factores de autenticación.
                                                        </td>
                                                        <td>
                                                            Modificación de los factores de autenticación como contraseña y segundo factor de autenticación.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </li><br>
                                    <!-- 1.4	Pagos en el Sitio. -->
                                    <li style="font-size:16px!important">
                                        Pagos en el Sitio.
                                        <span style="font-weight: normal;">
                                            Cuando los Usuarios debieren hacer un pago relacionado exclusivamente con el Sitio y el Negocio, excluyendo expresamente aquellos que resulten de su interacción con Prestadores de Servicios, éstos podrán ser hechos mediante transferencias inter o intra-banacarias, tarjetas de débito, de cualquier entidad financiera autorizada para recibir o realizar depósitos.
                                        </span>
                                    </li><br>
                                    <!-- 1.5	Notificaciones a los Usuarios. -->
                                    <li style="font-size:16px!important">
                                        Notificaciones a los Usuarios.
                                        <span style="font-weight: normal;">
                                            Los Usuarios reconocen y aceptan que las notificaciones, avisos o acuerdos de cualquier tipo, que debieran hacerse por virtud de la ley, del Sitio o del Negocio, serán hechos (i) por medio de su cuenta en el Sitio y (ii) notificados al correo electrónico que para tal efecto registren en su cuenta en el Sitio, con 30 (treinta) días naturales de anticipación a la fecha que entren en vigor dichos cambios, 
                                            entendiéndose que los Usuarios otorgan su consentimiento a dichas modificaciones o adiciones si no ejerce su derecho de dar por terminada su relación con Red Girasol, manifestando su voluntad de terminar dicha relación en el plazo de 30 (treinta) días naturales siguientes al día en que se efectúe la notificación antes referida o si continúa realizando operaciones.
                                            <br><br>
                                            Adicionalmente, Red Girasol podrá enviar las notificaciones mencionadas en el párrafo anterior por correo y a través de los estados de cuenta. Los avisos de modificaciones a estos Términos, Condiciones y Avisos contendrán, por lo menos: I. Denominación social y logotipo de Red Girasol; II. Domicilio y teléfono de contacto de Red Girasol, así como domicilio, teléfono y correo electrónico de la 
                                            Unidad Especializada de Atención de Usuarios (“UNE”); III. Resumen de todas las modificaciones realizadas; IV. Fecha a partir de la cual entran en vigor, y V. Su derecho para dar por terminada la relación con Red Girasol. Usted puede solicitar la terminación de la relación dentro de los 30 (treinta) días posteriores al aviso sin responsabilidad alguna a su cargo, y bajo las condiciones pactadas originalmente, 
                                            sin que Red Girasol pueda cobrarle penalización alguna por dicha causa ni cobrar cantidad adicional alguna con excepción de los adeudos que ya se hubieran generado a la fecha en que Usted solicite dar por terminada la relación. En el evento de que Usted no esté de acuerdo con las modificaciones realizadas, podrá solicitar la terminación de la relación con Red Girasol dentro de los 30 (treinta) días posteriores 
                                            al aviso señalado en el párrafo anterior, sin responsabilidad alguna a su cargo, con las limitaciones establecidas en cada contrato celebrado por Usted y de conformidad con la legislación aplicable.

                                        </span>
                                    </li><br>
                                    <!-- 1.6	Procedimiento para la contratación del Negocio. -->
                                    <li style="font-size:16px!important">
                                        Procedimiento para la contratación del Negocio.
                                        <span style="font-weight: normal;">
                                            El Usuario reconoce que, toda la información y/o cualquier análisis de riesgo, crédito, calificación de Solicitantes interesados en publicar un proyecto en el Sitio para obtener un crédito para la adquisición de Productos han sido preparadas en términos de la Metodología de Evaluación, Selección y Calificación de Solicitantes de Red Girasol la cual podrá ser consultada en https://www.redgirasol.com/modelo-de-riesgos, 
                                            por lo que dicha información es solamente estimada y para efectos informativos, por lo que Red Girasol de ninguna forma responde de la veracidad y/o certeza de la información presentada en el Sitio y/o de la información y documentación sometida por dichas personas y/o del análisis que Red Girasol haga de la misma. Aunado a lo anterior, Red Girasol tiene prohibido asegurar retornos o rendimientos sobre las inversiones
                                            o garantizar el éxito de dichas inversiones, en términos del artículo 20 de la Ley FinTech.
                                        </span>
                                    </li><br>
                                    <!-- 1.6	Servicios y Contenidos adicionales del Sitio. -->
                                    <li style="font-size:16px!important">
                                        Servicios y Contenidos adicionales del Sitio.
                                        <span style="font-weight: normal;">
                                             Mediante el uso del Sitio y del Negocio, el Usuario podrá acceder a, y/o utilizar, los diversos servicios y contenidos (los “<u>Servicios y Contenidos</u>”) puestos a su disposición por Red Girasol y/o sus subsidiarias y afiliadas y/o por terceros proveedores de servicios que, de tiempo en tiempo, puedan ofrecer sus productos o servicios a través del Sitio, del Negocio o de Red Girasol (cada uno, un “<u>Prestador de Servicios</u>”).
                                        </span>
                                    </li><br>
                                </ol>
                            </li>
                            <!-- 2. Restricciones  -->
                            <li>
                                Restricciones.<br><br>
                                <ol>
                                    <!-- 2.1 Restricciones a los Servicios y Contenidos. -->
                                    <li style="font-size:16px!important">
                                        Restricciones a los Servicios y Contenidos.
                                        <span style="font-weight: normal;">
                                            Para efectos de los Servicios y Contenidos del Sitio, los Usuarios reconocen y aceptan que es de su exclusiva responsabilidad el utilizar los Servicios y Contenidos del Sitio y del Negocio de conformidad con los usos y funcionalidades que se ofrecen y permiten en los mismos, 
                                            incluyendo en los presentes Términos, Condiciones y Avisos y conforme al Aviso de Privacidad. En este mismo sentido, el Usuario se obliga a utilizar el Sitio, los Servicios y Contenidos y el Negocio de modo que no atenten contra las normas de uso y convivencia en Internet, 
                                            las leyes de los Estados Unidos Mexicanos, las buenas costumbres, la dignidad de las personas y los derechos de terceros.
                                            <br><br>
                                            Los Usuarios reconocen y aceptan que los Servicios y Contenidos del Sitio y del Negocio se ofrecen para ser utilizados únicamente de manera personal, privada y particular por los Usuarios, por lo que no podrán de ninguna manera, copiarlos, modificarlos, darlos en licencia, 
                                            publicarlos, reproducirlos o utilizarlos con fines comerciales. Asimismo reconocen y aceptan que tienen prohibido el uso o aplicación de cualesquiera recursos técnicos, lógicos o tecnológicos por virtud de los cuales los Usuarios puedan beneficiarse, directa o indirectamente, 
                                            con o sin lucro, de la explotación no autorizada de los Servicios y/o Contenidos del Sitio y del Negocio, incluyendo la propiedad intelectual registrada o no registrada, presente y futura respecto al contenido, información, imágenes, software o cualquier tipo de datos que se 
                                            encuentren o relacionen con el Sitio, el Negocio o sean propiedad de Red Girasol y el grupo de empresas al que pertenece. Los usuarios, reconocen y aceptan asumir todas las consecuencias y responsabilidades que resulten del uso indebido del Sitio y del Negocio, excluyendo y 
                                            liberando a Red Girasol de cualquier tipo de responsabilidad por dichas conductas.
                                        </span>
                                    </li><br>
                                    <!-- 2.2 Restricción de acceso al Negocio y/o los Servicios y Contenidos y/o una operación de financiamiento colectivo en particular por excedencia de los porcentajes de participación.  -->
                                    <li style="font-size:16px!important">
                                        Restricción de acceso al Negocio y/o los Servicios y Contenidos y/o una operación de financiamiento colectivo en particular por excedencia de los porcentajes de participación. 
                                        <span style="font-weight: normal;">
                                            De la misma manera, los Usuarios reconocen y aceptan que Red Girasol podrá en cualquier momento restringir el acceso a o participación en una parte del Negocio y/o los Servicios y Contenidos y/o a una operación de financiamiento en particular, de conformidad con lo dispuesto en
                                            los Artículos 49 y 50 de las Disposiciones de Carácter General Aplicables a las Instituciones de Tecnología Financiera (en adelante la “<u>CUITF</u>”), toda vez que dicha operación u operaciones de financiamiento tengan como consecuencia que el Usuario en cuestión exceda de los porcentajes 
                                            permitidos como límite de inversión. En tal virtud, los Usuarios estarán sujetos a los siguientes límites de inversión:
                                        </span>
                                        <div class="w-full">
                                            <!-- TABLA DE RESTRICCIONES -->
                                            <table class="mb-4 w-full" style="margin-top: 15px; border: hidden !important;">
                                                <caption></caption>
                                                <thead>
                                                    <tr>
                                                        <!-- Sin headers -->
                                                    </tr>
                                                </thead>
                                                <tbody style="font-weight: normal;">
                                                    <tr style="font-weight: 700;">
                                                        <td style="border-bottom: hidden !important;">
                                                            
                                                        </td>
                                                         <td>
                                                            Tipo de Operación de Financiamiento Colectivo
                                                        </td>
                                                        <td>
                                                            Inversionista persona física
                                                        </td>
                                                        <td>
                                                            Inversionista persona moral
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border-bottom: hidden !important;border-top: hidden !important;font-weight: 700;">
                                                            <div class="eq-c">
                                                                <div class="fraction">
                                                                    <span class="fup"><i>NCI</i></span>
                                                                    <span class="bar">/</span>
                                                                    <span class="fdn"><i>&sum;IE + &sum;CI + &sum;NCI</i></span>
                                                                </div>
                                                                X 100 &le;
                                                            </div>
                                                        </td>
                                                        <td>
                                                            Deuda de Préstamos Personales entre Personas
                                                        </td>
                                                        <td>
                                                            7.5%
                                                        </td>
                                                        <td>
                                                            20%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border-bottom: hidden !important;border-top: hidden !important;">

                                                        </td>
                                                        <td>
                                                            Deuda de Préstamos Empresariales entre Personas o para el Desarrollo Inmobiliario
                                                        </td>
                                                        <td>
                                                            15%
                                                        </td>
                                                        <td>
                                                            20%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border-top: hidden !important;">

                                                        </td>
                                                        <td>
                                                            Capital, Copropiedad o Regalías
                                                        </td>
                                                        <td>
                                                            15%
                                                        </td>
                                                        <td>
                                                            20%
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p class="text-justify" style="font-weight: normal; border: solid 1px #ddd;padding: 12px;">
                                                 <i>
                                                    Donde: <br>
                                                    NCI = Compromisos de Inversión que se pretenden realizar en una misma solicitud de financiamiento. <br>
                                                    IE = Inversiones efectivas realizadas por el inversionista previamente a através de la institución de financiamiento colectiva. <br>
                                                    CI = Compromisos de Inversión previamente realizados en otras solicitudes de financiamiento por el Inversionista a través de la institución de financiamiento colectivo.
                                                </i>
                                            </p>
                                        </div>

                                        <span style="font-weight: normal;">
                                            <br>
                                            En este sentido, cuando un Usuario realice operaciones de financiamiento de distinto tipo a través del Sitio, el porcentaje aplicable al que se sujetarán dichas operaciones de financiamiento será aquel que corresponda al tipo de financiamiento de que se trate, conforme a la tabla anterior.
                                            <br><br>
                                            No obstante lo anterior, el Usuario no estará sujeto a los límites de inversión previamente enunciados cuando ocurran cualquiera de las siguientes circunstancias:
                                        </span><br><br>

                                        <ul style="list-style: disc;padding-left: 5rem;">
                                            <li style="font-size:16px!important">
                                                <span style="font-weight: normal;">
                                                    Cuando los Inversionistas mantengan un monto agregado de inversiones efectivas y compromisos de inversión que no supere el equivalente en moneda del curso legal de los Estados Unidos Mexicanos a 2,000 UDI's en el Sitio y/o el Negocio, esta les permitirá realizar compromisos de inversión 
                                                    por Inversionista en una misma solicitud de financiamiento hasta por el equivalente en moneda del curso legal de los Estados Unidos Mexicanos a 667 UDI's, tratándose de operaciones de financiamiento colectivo de deuda de préstamos personales entre personas o empresariales entre personas.
                                                </span>
                                            </li><br>
                                            <li style="font-size:16px!important">
                                                <span style="font-weight: normal;">
                                                    Cuando en el Sitio existan 25 o menos solicitudes de financiamiento colectivo publicadas, el compromiso de inversión a ser realizado por un mismo Inversionista a un Solicitante no podrá exceder del máximo entre 5 % del monto de financiamiento requerido o el equivalente en moneda del curso 
                                                    legal en los Estados Unidos Mexicanos a 167,000 UDI's.
                                                </span>
                                            </li><br>
                                            <li style="font-size:16px!important">
                                                <span style="font-weight: normal;">
                                                    Cuando Red Girasol, como parte de los esquemas para compartir riesgos con los Inversionistas, realice compromisos de inversión en algún Solicitante o proyecto, podrá aumentar los porcentajes referidos en esta sección, adicionando el porcentaje de inversión que Red Girasol se esté 
                                                    comprometiendo con el Solicitante, o el 25 %, lo que resulte menor.
                                                </span>
                                            </li>
                                        </ul><br>
                                        
                                        <span style="font-weight: normal;">
                                            Tratándose de Inversionistas Relacionados y Experimentados (como dichos términos se define en la CUITF). Adicionalmente, a estos Inversionistas no les resultarán aplicables los límites anteriores. En tal virtud, de ostentarse algún Usuario con dicha calidad, Red Girasol solicitará la información
                                            que acredite dicha calidad, de conformidad con lo establecido en el Aviso de Privacidad, mismo que podrá ser consultado en: <a to="https://www.redgirasol.com/aviso-de-privacidad">Aviso de Privacidad</a>; lo anterior sin perjuicio de que Red Girasol pueda solicitar información adicional.
                                            <br><br>
                                            Para los efectos de este apartado, cualquier referencia hecha a UDI's significará Unidades de Inversión como dicho término es definido y valuado por el Banco de México, de tiempo en tiempo, en el Diario Oficial de la Federación o en el siguiente enlace: <a href="https://www.banxico.org.mx/SieInternet/consultarDirectorioInternetAction.do?sector=8&accion=consultarCuadro&idCuadro=CP150&locale=es" target="_blank" rel="noopener noreferrer">Valores de UDIS</a>.
                                        </span>
                                    </li><br>
                                    <!-- 2.3 Restricciones en la calidad de la Información, del Sitio, del Negocio, de los Productos y de los Servicios y/o Contenidos. -->
                                    <li style="font-size:16px!important">
                                        Restricciones en la calidad de la Información, del Sitio, del Negocio, de los Productos y de los Servicios y/o Contenidos.
                                       <span style="font-weight: normal;">
                                            La información contenida en el Sitio que forme parte del Negocio ha sido incluida y elaborada por Red Girasol con base en la información que se ha visto reflejada por la interacción de los Usuarios en el Sitio y demás fuentes consideradas fidedignas, cuyo propósito es única y exclusivamente el de informar a los Usuarios y por lo tanto no implica ni representa una garantía sobre la información presentada. 
                                            La información, estimaciones, proyecciones y opiniones que pudieren ser incluidas en el Sitio como parte del Negocio podrán modificarse de acuerdo con la información que para tal efecto recabe Red Girasol, mismas que podrán variar con base en factores políticos, económicos y sociales ligados o no con los Usuarios y en general con los mercados financieros, tanto nacionales como extranjeros. 
                                            Nada de la información contenida en el Sitio deberá ser considerada como absoluta ni fija, así mismo, dicha información no representa sugerencias o recomendaciones de inversión por parte de Red Girasol y/o los Prestadores de Servicios, ni de sus funcionarios y/o empleados. De la misma manera, los Usuarios no deberán considerar que el Negocio otorga participación alguna sobre valores o que implica una 
                                            solicitud de compra o venta de valores, puesto que los Usuarios, Accionistas, Solicitantes e Proveedores únicamente participan en el Negocio respecto de los Productos. Por lo tanto, la responsabilidad sobre la utilización de dicha información recae única y exclusivamente en los Usuarios y el Usuario será el único y final responsable de determinar si una inversión, producto, plan, portafolio, estrategia 
                                            de inversiones o servicio es apropiado para él, basándose en sus objetivos de inversión y situación financiera personal.
                                            <br><br>
                                            Ni Red Girasol, sus subsidiarias y/o afiliadas, ni sus proveedores, socios comerciales o Prestadores de Servicios serán responsables de cualquier daño, perjuicio o pérdida que sufra el Usuario a consecuencia de demoras, inexactitudes, errores u omisiones de cualquier índole, cambios o mejoras que se realicen periódicamente a los Servicios y/o Contenidos, de los resultados obtenidos por la utilización de la 
                                            información contenida en el Sitio, o por su participación en el Negocio.

                                        </span>
                                    </li><br>
                                    <!-- 2.4 No garantía de rendimientos, utilidades y recuperación de inversiones. -->
                                    <li style="font-size:16px!important">
                                        No garantía de rendimientos, utilidades y recuperación de inversiones.
                                       <span style="font-weight: normal;">
                                            Red Girasol no asume ninguna obligación de garantizar rendimientos y/o utilidades y/o recuperación de la inversión ni de los Productos, ni de ser responsable de los daños, perjuicios y/o pérdidas que el Usuario pueda sufrir como consecuencia de las operaciones concertadas a través del Sitio y del Negocio, de conformidad con sus respectivos contratos y en términos de la legislación vigente.
                                        </span>
                                    </li><br>
                                    <!-- 2.5 Límites de responsabilidad y restricciones operativas en medios electrónicos. -->
                                    <li style="font-size:16px!important">
                                        Límites de responsabilidad y restricciones operativas en medios electrónicos.
                                       <span style="font-weight: normal;">
                                            Red Girasol no será responsable en caso de que no pueda efectuar o cumplir con las operaciones electrónicas por caso fortuito y/o fuerza mayor, por desperfectos, caída de sistemas o de cómputo, mal funcionamiento o suspensión de los medios de comunicación (incluyendo los medios electrónicos tales como los equipos, medios ópticos o de cualquier otra tecnología, sistemas automatizados de procesamiento de datos y 
                                            redes de telecomunicaciones, ya sean públicos o privados) (los “Medios Electrónicos”) o de cualquier otro servicio necesario para la prestación del servicio respectivo, incluyendo fallas o interrupciones derivadas de la tecnología.
                                            <br><br>
                                            Red Girasol no tendrá ninguna responsabilidad u obligación de ningún tipo por cualquier pérdida o pasivo sufrido por el Usuario debido a cualquier equipo, software o documentación que no sea producida o proporcionada por Red Girasol, en relación con el uso del Negocio en Medios Electrónicos.
                                            <br><br>
                                            El Usuario acepta que es su responsabilidad la administración de la información que genere mediante el uso del Sitio.
                                            <br><br>
                                            Cuando por negligencia, culpa, dolo o mala fe del Usuario, llegarán a ser rebasadas las medidas de seguridad para el acceso a los Medios Electrónicos e incluso induzcan al error, causándose con ello un daño o perjuicio al Usuario, Red Girasol quedará liberada de cualquier responsabilidad al ejecutar las instrucciones recibidas, sin perjuicio de las acciones civiles y/o penales que pudieran proceder en contra 
                                            de quien resulte responsable. Red Girasol quedará liberado de cualquier responsabilidad al ejecutar instrucciones recibidas a través de los Medios Electrónicos que haya puesto a disposición del Usuario, aun cuando la información empleada como clave de acceso, hubieren sido extraviadas por el Usuario o robadas, si éste no lo notificó de acuerdo a estos términos y condiciones a Red Girasol a fin de que se tomen 
                                            las medidas necesarias tendientes a evitar el acceso a terceros no autorizado.
                                            <br><br>
                                            El Usuario en este acto, acepta que él es el único responsable del uso que le da al equipo y/o dispositivos que usa para celebrar operaciones, ejercer derechos y/o de cualquier forma utilizar el Sitio o cualquier otro acto a los que se refiere el presente instrumento, razón por la cual el Usuario en este acto libera a Red Girasol de cualquier responsabilidad que pudiera derivarse, de manera enunciativa más no limitativa, 
                                            por el mal uso que le dé o llegue a dar a dicho equipo y/o dispositivos, así como por permitir que terceras personas, voluntaria o involuntariamente, accedan a su computadora u otros dispositivos donde almacena o llegue a almacenar la información empleada como clave de acceso o cualquier información personal del Sitio. Asimismo, el Usuario se obliga a utilizar programas o sistemas de cómputo con licencia vigente y a estar 
                                            enterado de las actualizaciones que dichos programas requieren para su uso seguro.
                                            <br><br>
                                            Será bajo la más exclusiva responsabilidad del Usuario, visitar sitios no seguros que pudieran insertar malware o algún otro software para extraer información confidencial del Usuario, así como bajar cualquier contenido de tales sitios y/o descargar sistemas o programas de cómputo que permitan compartir archivos (conocidos como “peer to peer”) que pudieran vulnerar la privacidad de su información y que el equipo y/o sistemas
                                            electrónicos que utiliza cuenten con la seguridad para evitar este tipo de intrusiones.
                                        </span>
                                    </li><br>
                                </ol>
                            </li>
                            <!-- 3. Responsabilidad de los Usuarios respecto del Sitio. -->
                            <li>
                                Responsabilidad de los Usuarios respecto del Sitio. <br><br>
                                <ol>
                                    <!-- 3.1	Responsabilidad sobre claves de acceso al Sitio. -->
                                    <li style="font-size:16px!important">
                                        Responsabilidad sobre claves de acceso al Sitio.
                                        <span style="font-weight: normal;">
                                            Los Usuarios registrados reconocen y aceptan que las claves de usuario, números de identificación personal y, en su caso, las contraseñas de acceso al Sitio, al Negocio y a los Servicios y/o Contenidos del Sitio y del Negocio, 
                                            son personales, confidenciales e intransferibles por lo que, en todo momento, la custodia y el uso de dicha información será de la exclusiva responsabilidad del Usuario.
                                            <br><br>
                                            Es responsabilidad del Usuario mantener confidencial su información de identificación y autenticación para su acceso al Sitio. En caso de que el acceso del Usuario fuera robado o extraviado, éste deberá notificar inmediatamente
                                            a Red Girasol <a target="_blank" href="https://www.redgirasol.com/reporte-robo-extravio">aquí</a> para bloquear su cuenta y permitirle al Usuario y que recupere su acceso.
                                            <br><br>
                                            El Usuario será el responsable por las Operaciones o modificaciones de configuración que hayan sido realizados en su cuenta previo al levantamiento de reporte por robo o extravío.
                                            <br><br>
                                            Red Girasol será el responsable por las Operaciones o modificaciones de configuración que deben ser bloqueadas posterior al levantamiento del reporte por robo o extravío.
                                            <br><br>
                                            Una vez que el Usuario haya solicitado el desbloqueo de las Operaciones y modificaciones de configuración a causa del reporte por robo o extravío, es el Usuario de nuevo responsable por dichas Operaciones y modificaciones de configuración en su cuenta
                                        </span>
                                    </li><br>
                                    <!-- 3.2	Extravío de claves de acceso.  -->
                                    <li style="font-size:16px!important">
                                        Extravío de claves de acceso.
                                        <span style="font-weight: normal;">
                                            En caso de que alguno de los Usuarios se encontrare en el supuesto de que su cuenta hubiere sido accedida por un tercero sin su consentimiento, el Usuario deberá, a su propia cuenta y responsabilidad, dar aviso a Red Girasol, para que éste inhabilite 
                                            dicha cuenta y permita que el Usuario se registre bajo una nueva cuenta y para que por lo tanto, siga participando en el Negocio y en el Sitio.
                                        </span>
                                    </li><br>
                                    <!-- 3.3	Cancelación de cuentas en el Sitio.  -->
                                    <li style="font-size:16px!important">
                                        Cancelación de cuentas en el Sitio.
                                        <span style="font-weight: normal;">
                                            Sin perjuicio de lo anterior, cualquier Usuario podrá dar su cuenta de baja mediante el procedimiento que indique el Sitio, cuando se actualice alguno de los siguientes supuestos: (i) que el Usuario se encuentre libre de cualquier adeudo o asuntos pendientes 
                                            de cobrar y no desee mantener su cuenta, (ii) que el Usuario incumpla con cualquier instrumento legal aplicable a los Usuarios, a discreción de Red Girasol, (iii) que exista sospecha de que alguna de las actividades en las que participa el Usuario tenga relación
                                            o esté involucrada con actividades ilegales, a discreción de Red Girasol, y (iv) que el Usuario incurra en faltas a la moral de cualquier tipo y en cualquier contexto, a discreción de Red Girasol.
                                            <br><br>
                                            Asimismo, Red Girasol se reserva el derecho de cancelar cualquier cuenta sin necesidad de previo aviso al Usuario y sin que ello signifique la terminación de los instrumentos jurídicos de los que el Usuario en cuestión sea parte y dicha cancelación no liberará 
                                            al Usuario de cualquiera obligación o responsabilidad que tenga a su cargo, ni implica la renuncia de Red Girasol de ejercer cualquier derecho o acción en contra del Usuario. No obstante lo anterior, cualquier saldo a favor en la cuenta del Usuario que se encuentre 
                                            en el Sitio será transferida a su cuenta de banco, salvo mandato en contrario por autoridad competente, jurisdiccional o administrativa. En cualquier caso de reactivación o restitución de una cuenta, el Usuario deberá ponerse en contacto con Red Girasol.
                                            <br><br>
                                            Red Girasol se reserva el derecho, a su propio juicio y consideración, de negar el acceso al Sitio o al Negocio a cualquier Usuario o persona, en cualquier momento y por cualquier causa, incluyendo, pero no limitado a la violación de los presentes términos y condiciones.
                                        </span>
                                    </li><br>
                                    <!-- 3.4	Protección de datos personales de los Usuarios. -->
                                    <li style="font-size:16px!important">
                                        Protección de datos personales de los Usuarios.
                                        <span style="font-weight: normal;">
                                            Red Girasol se obliga a tratar la información personal de los Usuarios como Datos Personales, como dicho término se encuentra definido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, de conformidad con el Aviso de Privacidad, 
                                            mismo que podrá ser consultado en: <a target="_blank" href="https://www.redgirasol.com/aviso-de-privacidad">Aviso de Privacidad</a>.
                                        </span>
                                    </li><br>
                                    <!-- 3.5	Factores de autenticación del Sitio. -->
                                    <li style="font-size:16px!important">
                                        Factores de autenticación del Sitio. <br><br>
                                        <span style="font-weight: normal;">
                                            Red Girasol establece dos Factores de Autenticación, uno de contraseña y otro en forma de contraseña dinámica (OTP). Ambos factores son independientes uno del otro por su naturaleza tecnológica. Ambos factores utilizan llaves cifradas independientes por lo que la 
                                            vulneración de uno no compromete la fiabilidad del otro.
                                            <br><br>
                                            Cuando el Usuario teclea su alias o correo electrónico (Identificador del Usuario), éste no se mostrará en la pantalla. Red Girasol almacena las contraseñas de manera segura en la base de datos a través del mecanismo de hashing en base a la técnica Bcrypt.
                                            Esta técnica consiste en el cifrado de contraseña a través de un hash de 184 bits.
                                        </span>
                                        <br><br>
                                        <ul class="text-justify" style="list-style-type: upper-roman;">
                                        <!-- I. Mecanismos y procedimientos de identificación y autenticación. -->
                                            <li style="font-size:16px!important">
                                                Mecanismos y procedimientos de identificación y autenticación. <br><br>
                                                <ul class="text-justify" style="list-style-type: upper-latin;padding-left: 5rem;">
                                                <!-- A -->
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            <i>Primer factor de autenticación.</i>
                                                            Los Usuarios que se hubieren registrado como usuarios en el Sitio podrán ingresar a sus respectivas cuentas en el Sitio, ingresando en el apartado correspondiente (i) (a) la clave de usuario que hubieren generado para tal efecto, o bien, (b) su correo electrónico y 
                                                            (ii) la clave de acceso/contraseña que hubiesen registrado para tal efecto en su registro como usuario en el Sitio.
                                                            <br><br>
                                                            Solamente el Usuario puede generar la contraseña y esto lo realiza al momento que se registra en el Sitio. Al generar la contraseña se le solicitará cumpla al menos con los siguientes parámetros:
                                                        </span>
                                                        <ul class="text-justify" style="list-style-type: decimal;padding-left: 5rem;"><br>
                                                        <!-- 1 -->
                                                            <li style="font-size:16px!important">
                                                                <span style="font-weight: normal;">
                                                                    Longitud mínima de 6 caracteres.
                                                                </span>
                                                            </li>
                                                        <!-- 2 -->
                                                            <li style="font-size:16px!important">
                                                                <span style="font-weight: normal;">
                                                                    Al menos un carácter alfanumérico o especial.
                                                                </span>
                                                            </li>
                                                        <!-- 3 -->
                                                            <li style="font-size:16px!important">
                                                                <span style="font-weight: normal;">
                                                                    No se puede utilizar como contraseña:
                                                                </span>
                                                                <ul class="text-justify" style="list-style-type: lower-latin;padding-left: 5rem;">
                                                                <!-- a -->
                                                                    <li style="font-size:16px!important">
                                                                        <span style="font-weight: normal;">
                                                                            El Identificador de Usuario.
                                                                        </span>
                                                                    </li>
                                                                <!-- b -->
                                                                    <li style="font-size:16px!important">
                                                                        <span style="font-weight: normal;">
                                                                            El nombre de la Institución, p.ej., RedGirasol, redgirasol, red girasol, REDGIRASOL, etc.
                                                                        </span>
                                                                    </li>
                                                                <!-- c -->
                                                                    <li style="font-size:16px!important">
                                                                        <span style="font-weight: normal;">
                                                                            Más de tres caracteres idénticos en forma consecutiva.
                                                                        </span>
                                                                    </li>
                                                                <!-- d -->
                                                                    <li style="font-size:16px!important">
                                                                        <span style="font-weight: normal;">
                                                                            Más de tres caracteres numéricos y alfabéticos en forma secuencial.
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul><br>

                                                        <span style="font-weight: normal;">
                                                            Para reestablecer una contraseña, el Usuario, en el inicio de sesión debe indicar la opción para reestablecer la contraseña, posteriormente debe proporcionar su alias o correo electrónico, inmediatamente después recibirá un correo el cual contendrá un vínculo único y temporal (24 horas de vigencia) a través del cual podrá reestablecer su contraseña.
                                                            <br><br>
                                                            El Usuario puede cambiar su contraseña en la sección de Seguridad. Deberá proporcionar su contraseña actual y posteriormente indicar la nueva contraseña en dos ocasiones.  La nueva contraseña deberá contar con los parámetros mínimos de seguridad para que sea válida.
                                                        </span>
                                                    </li><br>
                                                <!-- B -->
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            <i>Segundo factor de autenticación.</i>
                                                            Después de haber iniciado sesión en el Sitio, los Usuarios que deseen acceder al Negocio deberán autenticar dicho inicio de sesión a través de un segundo factor de autenticación, el cual consiste en (i) ingresar a la sección de “Mi Perfil” en la cuenta del Usuario y (ii) escanear un código de autenticación rápida (“Código QR”, por sus siglas en inglés), 
                                                            que será mostrado en el Sitio después de iniciar sesión y mismo que corresponderá y será generado automáticamente por el Sitio con base en la cuenta del Usuario, con un dispositivo móvil que cuente con un lector de Códigos QR desarrollado por terceros ajenos a Red Girasol (ej. Google Authenticator, Authentication y cualesquiera otros). Habiendo satisfecho lo anterior, 
                                                            el Usuario en cuestión podrá acceder al Negocio. Lo anterior, en el entendido que dicho segundo factor de autenticación podrá ser solicitado para cualesquiera otros procedimientos o interacciones en el Sitio, cuando estos puedan tener o tengan un efecto en el Negocio o bien en la interacción del Usuario en el Sitio.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </li><br>
                                        <!-- II. Mecanismos y procedimientos para notificaciones relacionadas con el Negocio. -->
                                            <li style="font-size:16px!important">
                                                Mecanismos y procedimientos para notificaciones relacionadas con el Negocio. <br><br>
                                                <span style="font-weight: normal;">
                                                    La autorización de Operaciones por parte de los Usuarios en Red Girasol requerirá la captura de un Segundo Factor de Autenticación establecidos en estos Términos y Condiciones.
                                                    <br><br>
                                                    Las Operaciones para las que Red Girasol requiere de un Segundo Factor de Autenticación por parte de los Usuarios son las siguientes:
                                                    <br><br>
                                                </span>

                                                <ul style="list-style: disc;padding-left: 5rem;">
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            Realizar compromisos de inversión o retirar sus recursos.
                                                        </span>
                                                    </li>
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            Registro o modificación de Cuentas Destino.
                                                        </span>
                                                    </li>
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            Cambio y desbloqueo de Contraseña de Acceso.
                                                        </span>
                                                    </li>
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            Alta y modificación del medio de notificación.
                                                        </span>
                                                    </li>
                                                    <li style="font-size:16px!important">
                                                        <span style="font-weight: normal;">
                                                            Consultas de estados de cuenta de uno o más periodos.
                                                        </span>
                                                    </li><br>
                                                </ul>

                                                <span style="font-weight: normal;">
                                                    Antes de la ejecución de las instrucciones para realizar compromisos de inversión, el Sitio mostrará una pantalla en la que resume sus condiciones, para darle certeza al Usuario de cada Operación que realiza.
                                                    <br><br>
                                                    Las Operaciones realizadas por los Usuarios se notifican a los mismos a través del correo electrónico definido por ellos como medio de notificación. Asimismo, cuando el Usuario solicita el cambio de correo 
                                                    electrónico para la notificación de operaciones, esto se notifica tanto al correo electrónico anterior, como al nuevo.
                                                    <br><br>
                                                    De conformidad con lo previsto en el Aviso de Privacidad, mismo que podrá ser consultado en: <a target="_blank" href="https://www.redgirasol.com/aviso-de-privacidad">Aviso de Privacidad</a>, Red Girasol notificará a los Usuarios de cualesquiera actividades relacionadas 
                                                    con el Negocio y su interacción con el mismo y/o con el Sitio por medio del correo electrónico que hubiere indicado el Usuario al momento de su registro como usuario en el Sitio. En tal virtud, Red Girasol pone 
                                                    a disposición de los Usuarios, a través del Sitio, el apartado de “Notificaciones” en la sección de “Mi Perfil”, mediante el cual el Usuario podrá personalizar el tipo de notificaciones que desee recibir, o bien, 
                                                    desee dejar de recibir, según los intereses individuales de cada Usuario.
                                                </span>
                                            </li>
                                        </ul>
                                    </li><br>
                                    <!-- 3.6 Riesgos inherentes a la utilización del Sitio.  -->
                                    <li style="font-size:16px!important">
                                        Riesgos inherentes a la utilización del Sitio. 
                                        <span style="font-weight: normal;">
                                            El Usuario reconoce que sus interacciones en y con el Sitio y el Negocio y/o Red Girasol y/o sus Prestadores de Servicios las hará con pleno conocimiento de los riesgos que implican las operaciones en las que participa y que las mismas se realizan sin haber obtenido asesoría alguna de Red Girasol 
                                            respecto de cada operación y que, Red Girasol tiene prohibido asegurar retornos o rendimientos sobre las inversiones o garantizar el éxito de dichas inversiones. Con base en lo anterior, el Usuario libera, de manera absoluta y sin limitación alguna, a Red Girasol de toda responsabilidad que pudiere 
                                            derivar de los resultados que tengan las operaciones en las que el Usuario hubiere participado por medio del Sitio y del Negocio. Asimismo, los Usuarios liberan a Red Girasol, de la manera más amplia como en derecho proceda, por la infección a afectación de los Usuarios por virus de computadora y 
                                            similares o falla de sistema o línea que surjan de su interacción con el Sitio, el Negocio y/o de los sitios de los Prestadores de Servicios que intervengan en el Negocio.
                                            <br><br>
                                            Al aceptar estos Términos y Condiciones el Usuario manifiesta haber realizado el propio análisis sobre las consecuencias legales, fiscales, financieras y patrimoniales (entre otras), de realizar un compromiso de inversión y su consecuente consolidación. Incluyendo las ventajas de invertir, 
                                            los riesgos involucrados y los propios objetivos de inversión. Toda información que sea entregada al Usuario, en relación con el Negocio, contendrá declaraciones basadas en expectativas que involucran riesgos e incertidumbres comúnmente asociados al tipo de operaciones desarrolladas a través del Negocio. 
                                            Por lo anterior, los resultados pueden diferir de forma sustancial de cualquier expectativa que el Usuario pueda tener frente a sus inversiones. Cada usuario deberá considerar cuidadosamente toda la información que le sea presentada a través de los Servicios, incluyendo los riesgos inherentes a las inversiones que se realizarán a través de estos.
                                            <br><br>
                                            Así mismo, manifiesta que su decisión de inversión se basa en dicho análisis, por lo que reconoce no considerar la información presentada a través del Sitio como recomendaciones legales, fiscales o de inversión y asume que es su responsabilidad consultar asesores profesionales propios respecto de la adquisición, participación o disposición de tu inversión. 
                                        </span>
                                    </li><br>
                                    <!-- 3.7	Riesgos inherentes a los Prestadores de Servicios.   -->
                                    <li style="font-size:16px!important">
                                        Riesgos inherentes a los Prestadores de Servicios.
                                        <span style="font-weight: normal;">
                                            Respecto de los servicios y contenidos que presten los Prestadores de Servicios dentro, mediante o como parte del Sitio o del Negocio, el Usuario reconoce y acepta que la función de Red Girasol se limita exclusivamente, y para conveniencia del Usuario, a: (i) informar al Usuario la existencia de dichos productos y/o servicios; 
                                            y (ii) proporcionar un medio para poner en contacto al Usuario con los Prestadores de Servicios.
                                            <br><br>
                                            Red Girasol no es ni podrá ser considerado como proveedor de los bienes y/o servicios que se ofrecen en las páginas y/o sitios de los Prestadores de Servicios. La inclusión de dichas páginas y/o enlaces no implica la aprobación, respaldo, patrocinio, recomendación o garantía, por parte de Red Girasol, de los servicios y bienes 
                                            que se comercializan en los mismos ni del contenido de dichas páginas. No existe ningún tipo de relación laboral, asociación o sociedad, entre Red Girasol y dichos Prestadores de Servicios. Toda asesoría, consejo, declaración, información y contenido de las páginas de Prestadores de Servicios enlazadas o dentro del Sitio y/o 
                                            del Negocio representan las opiniones y juicios de dicho Prestador de Servicios, consecuentemente, Red Girasol no será responsable de ningún daño, perjuicio o pérdida que sufra el Usuario a consecuencia de los mismos, incluyendo, de manera enunciativa más no limitativa, los daños causados por pérdidas económicas, pérdidas de 
                                            datos o programas o pérdidas de los Productos. Red Girasol no garantiza ni asume responsabilidad alguna por los daños, perjuicios y/o pérdidas de cualquier clase que pudieran causarse por el funcionamiento, disponibilidad, accesibilidad, continuidad, mantenimiento de los Productos, información, datos, archivos, obligaciones, 
                                            ofertas, productos y cualquier clase de material existente en los sitios enlazados con relación al Negocio.
                                            <br><br>
                                            Por consiguiente, el Usuario debe extremar prudencia en la valoración y utilización de los servicios, información, datos, archivos, productos y cualquier clase de material de terceros existente en los sitios de los Prestadores de Servicios enlazados. El acceso a cualquier sitio enlazado al Sitio es bajo el propio riesgo y responsabilidad del Usuario.
                                        </span>
                                    </li>
                                </ol>
                            </li><br>
                            <!-- 4. Disponibilidad del Sitio. -->
                            <li>
                                Disponibilidad del Sitio. <br><br>
                                <span  style="font-size:16px!important;font-weight: normal;">
                                    Sin perjuicio de lo anterior y en atención a los derechos de los Usuarios del Sitio conforme a las Disposiciones de Carácter General de la CONDUSEF en Materia de Transparencia y Sanas Prácticas 
                                    aplicables a las Instituciones de Tecnología Financiera y al plan de continuidad de negocio de Red Girasol, Red Girasol hace del conocimiento de los Usuarios, que el Sitio deberá estar disponible 
                                    las veinticuatro horas del día, los trescientos sesenta y cinco días del año, salvo por aquellos periodos que conforme a las necesidades de mantenimiento, actualización y/o causas análogas del Sitio o de Red Girasol, 
                                    la operación y funcionamiento del Sitio y/o los Servicios y Contenidos deban ser suspendidos, lo anterior en el entendido que dichos periodos serán previamente notificados a los Usuarios, 
                                    conforme a los procedimientos de notificación previstos en estos Términos, Condiciones y Avisos de Uso del Sitio, particularmente lo establecido en el inciso II del párrafo 3.5 del presente.
                                    <br><br>
                                    De la misma manera, Red Girasol hace del conocimiento de los Usuarios que en caso de ocurrir una falla en el Sitio que dure más de veinticuatro horas, éstas serán notificadas tanto a la CONDUSEF, 
                                    conforme al procedimiento previsto en las Disposiciones de Carácter General de la CONDUSEF en Materia de Transparencia y Sanas Prácticas aplicables a las Instituciones de Tecnología Financiera, 
                                    así como informará de dicha situación a sus Usuarios mediante el envío de correo electrónico o publicación en las redes oficiales de Red Girasol, con el fin de salvaguardar los derechos de los Usuarios. 
                                </span>
                            </li><br>
                            <!-- 5.	Misceláneas. -->
                            <li>
                                Misceláneas. <br><br>
                                <ol>
                                    <!-- 5.1	Responsabilidad e Indemnización.  -->
                                    <li style="font-size:16px!important">
                                        Responsabilidad e Indemnización.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            No obstante cualquier disposición en contrario incluida en el presente, el Usuario adquiere todas las responsabilidades directas y derivadas que resulten de su interacción y participación con y en el Sitio y/o el Negocio, 
                                            para lo cual el Usuario se obliga a defender, indemnizar, excluir de responsabilidad y sacar en paz a Red Girasol y a sus proveedores, funcionarios, directores, empleados y contratistas, de cualquier acción, demanda, 
                                            reclamación, daños, perjuicios, multas, sanciones, costos y gastos, de cualquier naturaleza o tipo, incluyendo pero no limitado a honorarios razonables legales y contables, que ejercite un tercero en contra del Usuario con 
                                            motivo de cualquier acción, inacción, omisión o negligencia de cualquier tipo o naturaleza.
                                            <br><br>
                                            De conformidad con lo establecido en el presente, el Usuario no podrá argumentar su desconocimiento de los presentes Términos, Condiciones y Avisos, así como tampoco de ninguna otra disposición legal o contractual que resulte o derive del Sitio y/o del Negocio.
                                        </span>
                                    </li><br>
                                    <!-- 5.2	Prevención de fraudes. -->
                                    <li style="font-size:16px!important">
                                        Prevención de fraudes.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            Para Red Girasol es muy importante la prevención de fraudes, por lo que se advierte al Usuario que para efectos del artículo 11 y 127 de la Ley FinTech, toda la documentación e información exhibida a Red Girasol debe ser verdadera, correcta y precisa, 
                                            ya que el incumplimiento a lo anterior le podrá generar responsabilidades al Usuario o Usuario, incluyendo prisión de dos a diez años y multas de 5,000 a 150,000 UMA, entre otros, con independencia de la facultad de Red Girasol de excluir al 
                                            Usuario del Sitio por no hacer un uso adecuado de la misma.
                                        </span>
                                    </li><br>
                                    <!-- 5.3	Aclaraciones. -->
                                    <li style="font-size:16px!important">
                                        Aclaraciones.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            Red Girasol pondrá a disposición de los Usuarios, a través de <a target="_blank" href="https://www.redgirasol.com/aclaraciones">https://www.redgirasol.com/aclaraciones</a> los medios para la presentación de dudas, consultas, aclaraciones y reclamaciones.
                                            <br><br>
                                            A los Usuarios que realicen consultas vía telefónica o de manera presencial en las oficinas de Red Girasol se les solicita que canalicen sus consultas a través del Sitio en cualquiera de las opciones señaladas.
                                        </span>
                                    </li><br>
                                    <!-- 5.4	Propietario Real y Manifestación. -->
                                    <li style="font-size:16px!important">
                                        Propietario Real y Manifestación.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            Al celebrar operaciones con Red Girasol, el Usuario manifiesta lo siguiente:
                                            <br><br>
                                            <i>
                                                “Declaro bajo protesta de decir verdad que actúo por nombre y cuenta propia; que soy el propietario real de los recursos de ésta transacción, así como también que los datos y documentos proporcionados a Red Girasol son auténticos y el origen, 
                                                manejo o destino de los recursos con los que opero no se encuentran relacionados con operaciones que pudieran favorecer, prestar ayuda, auxilio o cooperación de cualquier especie para la comisión de los delitos previstos en los artículos 139 (Terrorismo), 
                                                139 Quáter (Financiación al Terrorismo), 148 BIS (Terrorismo Internacional) del Código Penal Federal, o que pudiesen ubicarse en los supuestos del artículo 400 BIS (Operaciones con Recursos de Procedencia Ilícita) del mismo ordenamiento legal.”
                                            </i>
                                            <br><br>
                                            En caso de manifestar que realiza la Operación en nombre y representación de un tercero, se deberá identificar al propietario real en términos de las Disposiciones de carácter general a que se refiere el Artículo 58 de la Ley FinTech.
                                        </span>
                                    </li><br>
                                    <!-- 5.5	Legislación Aplicable y Jurisdicción. -->
                                    <li style="font-size:16px!important">
                                        Legislación Aplicable y Jurisdicción.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            El Usuario y Red Girasol, se someten expresamente, para la interpretación, cumplimiento y ejecución de los presentes Términos, Condiciones y avisos a la legislación federal de los Estados Unidos Mexicanos y a la jurisdicción y competencia de los tribunales 
                                            ubicados en la Ciudad de Monterrey, Nuevo León, Estados Unidos Mexicanos, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa, incluyendo, 
                                            en caso de aplicarles a la excepción de <i>forum non conveniens</i>.
                                            <br><br>
                                            El Usuario así mismo reconoce y acepta que hasta en tanto no exista una sentencia firme que no acepte recurso alguno en su contra, no podrá dar publicidad directa o indirecta, informes, denunciar o de cualquier manera hacer del conocimiento público de manera 
                                            directa o indirecta, incluyendo por negligencia o culpa, el procedimiento jurisdiccional o en su caso administrativo a terceros ajenos a Red Girasol y al propio Usuario.
                                        </span>
                                    </li><br>
                                    <!-- 5.6	Acuerdo Único, aplicabilidad y modificaciones.-->
                                    <li style="font-size:16px!important">
                                        Acuerdo Único, aplicabilidad y modificaciones.
                                        <span  style="font-size:16px!important;font-weight: normal;">
                                            El presente representa el acuerdo único y total entre Red Girasol y el Usuario respecto al Sitio, y reemplaza todas las comunicaciones, propuestas y acuerdos previos tanto orales, escritos y electrónicos entre Red Girasol y el Usuario en relación con el Sitio, 
                                            salvo por lo previsto en el Aviso de Privacidad. Si alguna disposición del presente resulta ser inaplicable o inválida, esa disposición se eliminará a la extensión mínima necesaria para que los restantes términos y condiciones permanezcan vigentes y sean ejecutables. 
                                            Cualquier modificación a estos Términos y Condiciones será notificada a los Usuarios mediante correo electrónico y a través del Sitio con por lo menos 30 (treinta) días naturales previo a su surtimiento de efectos. En consecuencia, el Usuario acepta que es su obligación 
                                            el revisar periódicamente estos Términos y Condiciones y leerlos cada vez que pretenda utilizar el RedGirasol.
                                        </span>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "LatestTermsContract"
}
</script>

<style scoped>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

    ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    } 

    ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
    }

    li ol > li {
    margin: 0;
    }

    li ol > li:before {
    content: counters(item, ".") " ";
    }

    .fraction {
        display: inline-block;
        vertical-align: middle; 
        margin: 0 0.2em 0.4ex;
        text-align: center;
    }
        .fraction > span {
            display: block;
            padding-top: 0.15em;
        }
            .fraction span.fdn {border-top: thin solid black;}
            .fraction span.bar {display: none;}

    .custom-table th, td {
        border: solid 1px #ddd;
    }
</style>