<template>
    <section>
        <div class="container-fluid">
            <div class="col-md-12">
                <div class="alt-2"></div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div>
                            <h1 class="big_title text-center">
                                Aviso de Privacidad
                                <br>
                            </h1>
                            <h3 class="light_title text-center">
                                Fecha de actualización: 13 de mayo de 2022
                            </h3>
                            <br>
                        </div>

                        <div class="text-justify px-3">
                            <strong>El presente Aviso de Privacidad se hace del conocimiento de los Usuarios en cumplimiento de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“<u>LFPDPPP</u>”).</strong><br><br>

                            <strong>1.	Identidad, domicilio e información de contacto del responsable.</strong><br><br>
                            Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo, ("<u>Red Girasol</u>"), con domicilio en Eugenio Garza Sada Ext. 3820 Int. 801, Colonia Mas Palomas, Monterrey, Nuevo León, CP.64780, México, será el responsable del tratamiento de los datos personales de los Usuarios, del uso que se da a los mismos y de su protección. El presente Aviso de Privacidad provee información sobre la forma en que Red Girasol, así como sus empresas filiales, usan los datos personales de los Usuarios, y sobre los derechos que los mismos tienen como usuarios del Sitio.<br><br>
                            Red Girasol ha designado a un agente de protección de datos (“<u>DDP</u>” como dicho término se define más adelante) el cual será responsable de la supervisión de las preguntas relacionadas con este Aviso de Privacidad. En caso de tener los Usuarios alguna pregunta relacionada con éste Aviso de Privacidad o si desean obtener información al respecto, deberán ponerse en contacto con el DDP mediante los siguientes datos:<br><br>

                            <strong>Red Girasol, S.A.P.I, de C.V., Institución de Financiamiento Colectivo </strong><br>
                            Ave. Eugenio Garza Sada 3820 interior 801 <br>
                            Colonia Mas Palomas <br>
                            Monterrey, Nuevo León C.P. 64780 <br>
                            <a href="mailto:contacto@redgirasol.com" style="color: blue"><u>contacto@redgirasol.com</u></a> <br>
                            A/A: Departamento de Protección de Datos <br><br>

                            <strong>2.	¿Qué datos personales se recaban de los Usuarios? </strong><br><br>
                            Red Girasol no revelará información de carácter personal e identificable (como ser nombre y dirección de correo electrónico) a menos que los Usuarios, voluntariamente, decidan revelar tales u otros datos para fines específicos, tales como el funcionamiento y operación del Sitio y de la Comunidad Girasol, publicación de proyectos y/o mandar quejas o sugerencias. Red Girasol no venderá, comercializará ni arrendará información de carácter personal e identificable a terceros. La información de los Usuarios podría ser utilizada para la investigación relacionada con patrones de uso, desarrollo de productos, para el funcionamiento del Sitio, generación y publicación de proyectos, personalización de sitios online y marketing dirigido.<br><br>
                            Para las finalidades establecidas en este Aviso de Privacidad, Red Girasol, en ciertos casos pudiera obtener de los Usuarios los siguientes datos personales:<br>
                            <ol class="text-justify" style="list-style-type: upper-roman;font-weight: bold; margin-top: 1rem;">
                                
                                <!-- I. -->
                                <li style="font-size:16px!important"> 
                                    <span style="font-weight: 700">
                                        En caso de Usuarios que sean personas físicas que sean de nacionalidad mexicana o de nacionalidad extranjera en condiciones de estancia de residente temporal o residente permanente en términos de la Ley de Migración: 
                                    </span>
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <li style="font-size:16px!important">
                                        <!-- A. -->
                                            <span style="font-weight: normal">
                                                Los datos de identificación siguientes, provenientes de un documento válido de identificación personal oficial vigente emitido por autoridad competente o del documento que acredite su legal estancia en el país: 
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Apellido paterno, apellido materno y nombre o nombres sin abreviaturas. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Género. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Fecha de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Entidad Federativa de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">País de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Nacionalidad. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Clave de elector, en su caso.</span>
                                                </li>
                                            </ol>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- B. -->
                                            <span style="font-weight: normal">
                                                Domicilio particular del país de residencia compuesto por nombre de la calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su caso, interior; colonia o urbanización; alcaldía, delegación, municipio o demarcación política similar que corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia, departamento o demarcación política similar que corresponda, en su caso; código postal y país. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- C. -->
                                            <span style="font-weight: normal">
                                                Ocupación, profesión, actividad o giro del negocio al que se dedica el Usuario.
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- D. -->
                                            <span style="font-weight: normal">
                                                Clave Única de Registro de Población. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- E. -->
                                            <span style="font-weight: normal">
                                            Firma Autógrafa Digitalizada. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- F. -->
                                            <span style="font-weight: normal">
                                                Número telefónico en que se pueda localizar. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- G. -->
                                            <span style="font-weight: normal">
                                                Dirección de correo electrónico. 
                                            </span>
                                        </li>
                                    </ol>
                                </li><br>

                                <!-- II. -->
                                <li style="font-size:16px!important">
                                    <span  style="font-weight: 700;">
                                        Tratándose de Usuarios que sean personas morales de nacionalidad mexicana:
                                    </span> 
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <!-- A. -->
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal">
                                                Los datos de identificación siguientes:
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Denominación o razón social. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Giro mercantil, actividad u objeto social. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Nacionalidad.  </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Clave del Registro Federal de Contribuyentes con homoclave y, en su caso, número de identificación fiscal o equivalente, así como el país o países que lo asignaron. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Número de serie de la Firma Electrónica Avanzada. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Domicilio, compuesto por nombre de la calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su caso, interior; colonia o urbanización; alcaldía, delegación, municipio o demarcación política similar que corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia, departamento o demarcación política similar que corresponda, en su caso; código postal y país. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Número telefónico del domicilio. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Dirección de correo electrónico. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Fecha de constitución. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Apellido paterno, apellido materno y nombre o nombres del administrador o administradores, director, gerente general o apoderado legal que, con su firma, puedan obligar a la persona moral a celebrar la Operación, proveniente de un documento válido de identificación personal oficial vigente emitido por autoridad competente.  </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Firma Autógrafa Digitalizada del representante legal.  </span>
                                                </li>
                                            </ol>
                                        </li><br>

                                        <!-- B -->
                                        
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal">
                                                La versión digital de los documentos siguientes: 
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">El documento o el testimonio o copia certificada del instrumento público que acredite su legal existencia, inscrito en el registro público correspondiente, o cualquier instrumento o documento que acredite fehacientemente su existencia. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Cédula de Identificación Fiscal expedida por la Secretaría y, en su caso, del documento en el que conste la asignación del número de identificación fiscal o su equivalente expedido por autoridad competente y constancia de la Firma Electrónica Avanzada. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Comprobante del domicilio. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Testimonio o copia certificada del instrumento expedido por fedatario público que contenga los poderes del representante o representantes legales, cuando no estén contenidos en el instrumento público que acredite la legal existencia de la persona moral.</span>
                                                </li>
                                            </ol>
                                        </li><br>
                                    </ol>
                                    
                                </li>

                                <!-- III. -->
                                <li style="font-size:16px!important">
                                    Datos de IP y navegación: 
                                    <span  style="font-weight: normal;">
                                        Una dirección de Protocolo de Internet ("IP") consistente en un número que se asigna automáticamente a su computadora toda vez que usted navega en la red. 
                                        Los servidores de red identifican automáticamente su computadora por medio de la dirección IP. Cuando los visitantes interactúan con el Sitio, los servidores de Red Girasol registran la dirección de IP del Usuario. 
                                        Normalmente no se enlazan direcciones IP a ningún dato de carácter personal que pudiera ser identificable, lo cual significa que la sesión de un Usuario se registra, pero para Red Girasol, dicho visitante permanece en el anonimato. 
                                        Red Girasol recolecta direcciones IP para fines de administración de sistemas y para regular el uso del Sitio. Además, Red Girasol utiliza direcciones IP tanto para la recolección de información estadística que será enviada a terceros y/o Prestadores de Servicio como para otros fines de marketing y promoción.
                                    </span> 
                                </li><br>
                                
                                <!-- IV. -->
                                <li style="font-size:16px!important">
                                    Datos Biométricos.
                                    <span  style="font-weight: normal;">
                                        Red Girasol recabará únicamente los siguientes datos biométricos con el fin de autenticar la identidad de los Usuarios, o las personas que, en su caso, las representen:
                                    </span>  
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento facial; </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de firma; </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de escritura; y </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de voz. </span>
                                        </li>
                                    </ol>
                                </li><br>
                            </ol>

                            <p class="text-justify">
                                <u>Datos Personales Sensibles.</u> Red Girasol no recabará de los Usuarios datos personales que sean, o podrían llegar a ser considerados como datos personales sensibles por la LFPDPPP.
                                <br><br>
                                Cuando el Usuario ingresa al Sitio, éste podrá estar utilizando plataformas digitales, sitios o plataformas de terceros, o bien tecnologías como pueden ser cookies, cookies Flash, píxeles y web bugs, entre otras. En caso de habilitar los Usuarios en su dispositivo una función de prevención de cookies, algunos de los servicios y funcionalidades del Sitio podrán dejar de operar o bien operar de manera diferente.
                            </p>

                            <br>
                            <strong>3.	¿Para qué se utilizan los datos personales de los Usuarios?</strong>
                            <p class="text-justify">La obtención y tratamiento de los datos personales de Usuarios se realiza para las siguientes finalidades, mismas que Red Girasol considera como necesarias para la existencia, mantenimiento y cumplimiento de la Comunidad Girasol y de la relación que se sostiene con los Usuarios:</p>

                            <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold;margin-top:1rem;">
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Proporcionar información de los productos de Red Girasol cuando el Usuario lo solicite;</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Recibir quejas, y dar seguimiento a solicitudes;</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Para encuestas de servicios que permitan a Red Girasol mejorar sus productos;</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Para fines de mercadotecnia y/o estudio de mercado, sin fines de lucrar con la información o realizar contactos no deseados;</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Emisión de facturas;</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Generación de cotizaciones; y</span> </li><br>
                                <li style="font-size:16px!important"> <span  style="font-weight: normal;">Análisis de factibilidad técnica y económica para proyectos solares y Sistemas.</span> </li>
                            </ol>

                        <p class="text-justify">Cuando el Usuario así lo indique, Red Girasol procederá a dar al Usuario de baja de su base de datos para enviar información de los productos de Red Girasol, a través de la cual recibirá mensajes publicitarios, mismos que el Usuario podrá solicitar dejar de recibir en cualquier momento, utilizando el mecanismo incluido en todos dichos correos. </p>

                        <br>
                        <strong>4.	Remisiones de datos personales a proveedores.</strong> <br>
                        <p class="text-justify">Red Girasol podrá remitir total o parcialmente los datos personales de los Usuarios a Prestadores de Servicios que apoyen a Red Girasol en algún proceso. Dichos Prestadores de Servicios incluyen: (i)  empresas de tecnología que prestan diversos servicios de telecomunicaciones o infraestructura, y (ii) empresas que prestan servicios de mercadotecnia y estudios de mercado. En todos estos casos, Red Girasol se asegura de que estas empresas asuman obligaciones contractuales que permitan que los datos personales de los Usuarios se encuentren protegidos, además de que se hace del conocimiento de los Prestadores de Servicios, las obligaciones aplicables conforme a la LFPDPPP. </p>

                        <br>
                        <strong>5.	¿A quién puede Red Girasol transferir sus datos personales?  </strong>
                        <p class="text-justify">Red Girasol podrá transferir los datos personales de los Usuarios a otras empresas de Red Girasol, Usuarios registrados o no registrados y a autoridades en ciertos casos, para las finalidades establecidas en este Aviso de Privacidad. Estas transferencias incluyen las siguientes:</p>
                        
                        <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">Red Girasol puede transmitir los datos personales de los Usuarios a otras empresas de su mismo grupo corporativo. Por ello, los datos pueden ser transferidos a sus empresas filiales, mismas que pueden estar localizadas en México o en otros países. Las filiales de Red Girasol que podrían recibir o consultar los datos personales de los Usuarios han implementado medidas y políticas para la protección de los datos personales de los mismos; estas políticas resultan consistentes con las de Red Girasol y pretenden cumplir con lo dispuesto por las leyes de cada país aplicable.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">Así mismo, Red Girasol podrá transferir datos personales de los Usuarios del Sitio a autoridades administrativas o judiciales, cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, la protección de los derechos de Red Girasol, la protección de derechos de terceros o para la procuración o administración de justicia.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">Así mismo, ocasionalmente, Red Girasol podrá ser parte en alguna transacción corporativa (incluyendo fusiones, escisiones, ventas de activos o acciones, reestructuras corporativas, entre otras transacciones). En tanto que usualmente los Usuarios son uno de los activos a ser considerados como parte de la transacción, Red Girasol podrá comunicar los datos personales de los Usuarios a terceros en el contexto de dichas transacciones. Entre dichos terceros se encuentran, entre otros, los potenciales adquirentes de los activos y los asesores (legales, contables o financieros, entre otros) que participen en la transacción. Únicamente se comunicarán los datos personales a dichos terceros en la medida que dicha comunicación resulte necesaria para evaluar o completar la transacción o cuando la transferencia resulte necesaria como consecuencia de la misma.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">En otros casos, cuando la ley permita esta transferencia sin el consentimiento previo del titular, en términos del artículo 37 de la LFPDPPP. </span> </li>
                        </ol>

                        <p class="text-justify">Todas las transferencias anteriores (y las remisiones incluidas en la cláusula 4 anterior) pueden tener el carácter de nacional o internacional; asimismo, todas son precisas para el mantenimiento o cumplimiento de la relación comercial con los Usuarios y, por lo tanto, no se requiere del consentimiento del Usuario para realizarlas. </p>

                        <br>
                        <strong>6.	Colaboradores en materia de Datos Personales.</strong><br>

                        <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold;">
                            <li style="font-size:16px!important"> Proveedores de servicios de TI:  <span  style="font-weight: normal;">Red Girasol utiliza una serie de colaboradores de confianza que le proporcionan servicios de TI y de administración de sistemas, tanto para sus actividades con Usuarios como para sus sistemas internos de administración y TI.</span> </li><br>
                            <li style="font-size:16px!important"> Proveedores de pago y colaboradores de procesamiento:	<span  style="font-weight: normal;"> Para garantizar un proceso de pago seguro y eficaz en línea, mediante facturación o transferencias de dinero.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores de almacenamiento en la nube: <span  style="font-weight: normal;">Red Girasol almacena tanto sus propios datos como los de sus Usuarios en centros de almacén de datos seguros.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores y agencias para la detección y prevención de fraudes: <span  style="font-weight: normal;">Trabajan de manera conjunta con Red Girasol para garantizar que la misma no sufra ningún tipo de fraude.</span> </li><br>
                            <li style="font-size:16px!important"> Socios comerciales:  <span  style="font-weight: normal;">Para poder ofrecer anuncios, promociones y campañas publicitarias personalizadas, tanto cuando esté interactuando con Red Girasol a través del Sitio o del Negocio Girasol o en las redes sociales, como en cualquier otra ocasión.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores de redes sociales: <span  style="font-weight: normal;">Para tener presencia y permitirle a los Usuarios interactuar con Red Girasol en las plataformas que utilice.</span> </li><br>
                            <li style="font-size:16px!important"> Proveedores de revisión de encuestas, cuestionarios y productos: <span  style="font-weight: normal;">Ayudan a Red Girasol a garantizar que reciben todas las opiniones y comentarios importantes sobre la experiencia de los Usuarios en el Sitio y en el Negocio Girasol. </span> </li><br>
                            <li style="font-size:16px!important"> Autoridades en materia fiscal y aduanera, reguladores y demás autoridades competentes:  <span  style="font-weight: normal;">Solicitan informes de actividades de procesamiento y tratamiento en determinadas circunstancias.</span> </li><br>
                            <li style="font-size:16px!important"> Asesores profesionales:  <span  style="font-weight: normal;">Entre ellos abogados, banqueros, auditores y aseguradoras; ofrecen a Red Girasol servicios de asesoramiento y consultoría, banca, asistencia legal, seguros y contabilidad.</span> </li><br>
                        </ol>

                        <br>
                        <strong>7.	¿Cuáles son los derechos de los Usuarios? </strong>
                        <p class="text-justify">
                            De conformidad con la LFPDPPP, los Usuarios gozan de los derechos de Acceso, Rectificación, Cancelación y Oposición al Tratamiento (los “Derechos ARCO”); asimismo, cuentan con el derecho de revocar en cualquier tiempo el consentimiento otorgado para el tratamiento de sus datos personales en la medida que la ley lo permita. Para el  ejercicio de sus Derechos ARCO o su derecho de revocación o para formular cualquier duda o queja con relación al tratamiento de sus datos personales el Usuario deberá contactar al Departamento de Datos Personales (“DDP”). De la misma manera, en cualquier momento podrá modificar los datos personales que hubieren sido proporcionados desde la configuración de la cuenta de cada Usuario en el Sitio.
                            <br><br>
                            En virtud de lo establecido en los dos párrafos anteriores, los Usuarios que ejerzan sus Derechos ARCO mediante las formulaciones correspondientes al DDP, deberán incluir: (i) el nombre del Usuario que ejerce sus derechos, su domicilio y el medio de contacto para otorgarle la respuesta correspondiente; (ii) los documentos que acrediten su identidad y, en su caso, la de su representante legal; (iii) la descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de sus Derechos ARCO; (iv) los elementos o documentos que faciliten la localización de sus datos personales; y (v) en caso de así requerirlo, la documentación que sustente las modificaciones que pretende realizar el Usuario en cuestión a sus datos personales
                            <br><br>
                            Las solicitudes de los Usuarios serán evaluadas y contestadas en cuanto a su procedencia o rechazo por el DDP dentro del plazo máximo de 20 (veinte) días, contados a partir de la recepción de las formulaciones correspondientes, en términos de lo establecido en el artículo 32 de la LFPDPPP. Posterior al plazo anteriormente indicado y cuando proceda la solicitud de los Usuarios, Red Girasol contará con un plazo de 15 (quince) días para hacer efectivas las formulaciones procedentes de los Usuarios. Los plazos previamente enunciados para el ejercicio de Derechos ARCO podrán ser prorrogadas por periodos de la misma extensión cuando Red Girasol así lo requiera y se lo justifique al Usuario en cuestión, de conformidad con lo previsto en el artículo 97 del reglamento de la LFPDPPP.
                            <br><br>
                            Para efectos de claridad, se hace del conocimiento de los Usuarios que Red Girasol podrá negar el ejercicio de los Derechos ARCO cuando se actualicen cualquiera de los supuestos previstos en el artículo 34 de la LFPDPPP: (i) cuando el Usuario solicitante no sea el titular de los datos personales, o su representante legal no haya acreditado su personalidad; (ii) cuando no se encuentren los datos personales del Usuario solicitante en la base de datos de Red Girasol; (iii) cuando se lesionen derechos de terceros; (iv) cuando exista un impedimento legal, o la resolución de una autoridad competente, que restrinja el acceso a los datos personales, o no permita el ejercicio de Derechos ARCO; y (v) cuando la rectificación, cancelación u oposición haya sido previamente realizada. Lo anterior, en el entendido, en todo caso, que dichas circunstancias deberán ser notificadas por el DDP al Usuario solicitante dentro del plazo primeramente señalado en el párrafo que antecede y en el entendido que la negativa aquí referida podrá ser parcial y, por tanto, procederá la solicitud de Derechos ARCO del Usuario.
                            <br><br>
                            Para efectos de lo previsto en este apartado 7, Red Girasol hace del conocimiento de los Usuarios que en lo no previsto en el presente, Red Girasol se encuentra sujeto al cumplimiento de las obligaciones a su cargo previstas en el Capítulo IV de la LFPDPPP y el Capítulo VII del reglamento de la LFPDPPP, como responsable del tratamiento de los datos personales de los Usuarios                        
                        </p>
                        <br>
                        <strong>8.	¿Qué opciones ofrece Red Girasol para limitar el uso o divulgación de los datos personales de los Usuarios? </strong> <br>
                        <p class="text-justify">Red Girasol le ofrece a los Usuarios la posibilidad de tomar decisiones sobre cómo usa Red Girasol sus datos personales; para lo cual, Red Girasol le ofrece al Usuario diversos medios para informarse acerca de su decisión. </p><br>
                        <p class="text-justify">Si el Usuario no desea recibir mensajes publicitarios de Red Girasol o cancelar su suscripción a las bases de datos del mismo, el Usuario podrá: (i) solicitar cancelar su suscripción a las listas de correo de Red Girasol haciendo uso del mecanismo establecido en los correos electrónicos respectivos; y/o (ii) podrá contactar al DDP.</p>

                        <br>
                        <strong>9.	Seguridad de sus Datos Personales.</strong><br>
                        <p class="text-justify">Red Girasol ha adoptado medidas de seguridad físicas, organizacionales, y técnicas para proteger los datos personales de los Usuarios en contra de pérdida, uso o acceso no autorizado, en cumplimiento de lo dispuesto por la LFPDPPP.</p>

                        <br>
                        <strong>10.	Modificaciones al Aviso de Privacidad. </strong><br>
                        <p class="text-justify">Red Girasol se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad. El aviso modificado será publicado en lugares visibles en el Sitio o se le hará llegar al Usuario a través del correo electrónico proporcionado, en caso de contar con información de contacto. Los Usuarios podrán verificar que el Aviso de Privacidad ha sido modificado en virtud de que Red Girasol hará notar siempre la fecha de su última actualización. En caso que Red Girasol realice algún cambio sustancial en el tratamiento de los datos personales de los Usuarios, éstas modificaciones se le harán saber a los mismos a través de una notificación a los correos electrónicos de los Usuarios, cuando se cuente con datos de contacto, o anunciando estos cambios en el Sitio. Todo cambio en el Aviso de Privacidad entrará en vigor a los 15 (quince) días naturales de su respectiva publicación. Si el Usuario no está de acuerdo con las modificaciones, podrá contactar al DDP.</p>

                        <br>
                        <strong>11. Miscelánea</strong><br>

                        <p class="tex-justify">No obstante cualquier estipulación en contrario contenida en el presente y cualesquier otro documento referente o relacionado con Red Girasol, el Sitio o la Comunidad Girasol, el acceso al Sitio o a la Comunidad Girasol por cualquier plataforma o medio establece el consentimiento expreso y plano del presente Aviso de Privacidad. Cualquier modificación a este Aviso de Privacidad se dará a conocer a través del correo electrónico que sea proporcionado en el Sitio.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "LatestPrivacyContract"
}
</script>

<style scoped>

</style>